<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.NAME')"
                label-for="field-name"
              >
                <b-form-input
                  id="field-name-input"
                  v-model="dto.name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-description"
                label-cols-lg="2"
                :label="$t('COMMON.DESCRIPTION')"
                label-for="field-description-input"
              >
                <b-form-textarea
                  id="field-description-input"
                  v-model="dto.description"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="field-devices"
                label-cols-lg="2"
                :label="$t('CATALOGUE.DEVICES')"
                label-for="field-devices"
              >
                <multi-suggestion
                  id="deviceSuggestion"
                  suggestion-processor="deviceSuggestionProcessor"
                  :element-name="$t('DEVICE.DEVICE')"
                  :elements-name="$t('DEVICE.DEVICES')"
                  :selected-elements="selectedDevicesForSuggestion"
                  :addErrorNotification="true"
                  @on-element-added="addDevice"
                  @on-remove-element="removeDevice"
                />
              </b-form-group>
            </b-card-body>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button variant="success" v-on:click="onSaveClicked">{{
            $t("COMMON.SAVE")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MultiSuggestion from "@/view/component/MultiSuggestion";
import ApiService from "@/core/services/api.service";

export default {
  name: "catalogue",
  components: {
    MultiSuggestion,
  },
  data() {
    return {
      save: {
        resource: "/api/catalogue/save",
        requestType: "POST",
        requestParams: {},
      },

      dto: {
        id: null,
        created: null,
        changed: null,
        description: null,
        devices: [],
        teamId: null,
      },
      selectedDevicesForSuggestion: [],

      showOverlay: true,
      createNewStr: this.$t("CATALOGUE.CREATE_NEW_CATALOGUE"),
      editStr: this.$t("CATALOGUE.EDIT_CATALOGUE"),
    };
  },

  mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.CATALOGUES"), route: "/catalogues" },
      { title: title },
    ]);
  },

  created: async function () {
    if (this.$route.params.id != null) {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/api/catalogue/" + this.$route.params.id,
          requestType: "GET",
        })
        .then((response) => {
          for (const device of response.devices) {
            this.selectedDevicesForSuggestion.push(device);
          }
          for (const device of response.devices) {
            device.selected = true;
          }
          this.dto = response;
        })
        .catch(this.onError);
    }

    this.showOverlay = false;
  },

  methods: {
    addDevice(data) {
      this.selectedDevicesForSuggestion.push(data);
    },

    removeDevice(index, data) {
      this.selectedDevicesForSuggestion.splice(index, 1);
    },

    onSaveClicked: function () {
      const request = this.save;
      this.dto.teamId = ApiService.teamData.value;
      this.dto.devices.splice(0, this.dto.devices.length);
      if (this.selectedDevicesForSuggestion.length != 0) {
        for (const device of this.selectedDevicesForSuggestion) {
          this.dto.devices.push({ id: device.id });
        }
      }
      request.dto = this.dto;
      this.errors = [];

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError);
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
